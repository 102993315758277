import React from 'react'
import styled, { keyframes } from 'styled-components'
import Slide from 'react-reveal/Slide'
import Flip from 'react-reveal/Flip'
import {
  Container,
  ResponsiveContent,
  Column,
  SectionTitle,
  CenteredContent,
  Emphasis,
} from '../shared/layout'
import { Button } from '../shared/interactive'
import Lang from '../../util/lang'

const blinkAnimation = keyframes`
  0%, 32% {
    background: #11bb11;
    svg: {
      color: #fff;
    }
  }
  33%, 65% {
    background: #fff;
    svg: {
      color: #000
    }
  }
`

const HowItText = styled.div`
  padding: 0 4%;
  width: 100%;
`

const HowItTitle = styled(SectionTitle)`
  font-size: 3rem;
`

const SubTitle = styled.p``

const StepList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
`

const Step = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2% 0;
`

const StepIcon = styled.div`
  padding: 2%;
  margin-right: 3%;
  font-size: xx-large;
  outline: 1px solid black;
  animation-name: ${blinkAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`

const SubscribeButton = styled(Button)`
  margin-top: 4%;
  padding: 3% 4%;
  font-size: 1.1rem;
  border-radius: 25px;
`

const SubscribeLink = styled.a`
  text-decoration: none;
  color: #fff;
`

const HowItWorks = () => (
  <section data-testid='how-it-section'>
    <Container data-testid='how-it-container'>
      <ResponsiveContent>
        <Column>
          <img src='../assets/mobile-alerts.png' alt='mobile trading' height='500px' />
        </Column>
        <HowItText>
          <Column>
            <Slide right>
              <HowItTitle data-testid='how-it-title'>
                How It <Emphasis>Works</Emphasis>
              </HowItTitle>
            </Slide>
            <Slide right>
              <SubTitle data-testid='how-it-subtitle'>{Lang.howItWorks.subtitle}</SubTitle>
            </Slide>
            <StepList data-testid='how-it-step-list'>
              {Lang.howItWorks.steps.map((step) => (
                <Flip right key={step.text.substr(0, 7)}>
                  <Step key={step.text.substr(0, 7)} data-testid='how-it-step-list-item'>
                    <StepIcon data-testid='step-icon'>{step.icon}</StepIcon>
                    <CenteredContent data-testid='step-text'>{step.text}</CenteredContent>
                  </Step>
                </Flip>
              ))}
            </StepList>
            <Flip top delay={600}>
              <SubscribeButton data-testid='how-it-subscribe-btn'>
                <SubscribeLink href='/signup' data-testid='how-it-subscribe-link'>
                  {Lang.howItWorks.subscribeCall}
                </SubscribeLink>
              </SubscribeButton>
            </Flip>
          </Column>
        </HowItText>
      </ResponsiveContent>
    </Container>
  </section>
)

export default HowItWorks
