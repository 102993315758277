import React, { useState } from 'react'
import styled from 'styled-components'
import Navbar from './Navbar'
import AppLogo from './AppLogo'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'

const HeaderBar = styled.header`
  display: flex;
  flex-flow: row nowrap;
  position: sticky;
  top: 0;
  left: 0;
  /* height: 15vh; */
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    position: relative;
  }
`

const Header = () => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const closeMenu = () => {
    setOpen(false)
  }

  return (
    <HeaderBar>
      <AppLogo closeMenu={closeMenu} />
      <Navbar isLoggedIn={false} closeMenu={closeMenu} handleClick={handleClick} showMenu={open} />
    </HeaderBar>
  )
}

export default Header
