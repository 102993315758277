export default [
  {
    author: 'Luke R.',
    comment:
      "Guys, i gotta say...these alerts are awesome! I just made $5,883.37 in the last week alone. It's paid for itself already. THANK YOU SO MUCH!!!",
    thumbnail: '../assets/luke-thumbnail.png',
  },
  {
    author: 'Nancy D.',
    comment:
      "After spending so much time just trying to find a reliable service, i'm just grateful my friend told me about Bid Bull when he did. I haven't made millions overnight, but it's been reliable and consistent wins ever since.",
    thumbnail: '../assets/nancy-thumbnail.png',
  },
  {
    author: 'Damon B.',
    comment:
      "I'm still new to the business but i've really enjoyed the play-by-play and its so easy to follow along and profit. I hope you guys are around for a long while.",
    thumbnail: '../assets/damon-thumbnail.png',
  },
]
