import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Container, Row, Column, Title } from '../../shared/layout'
import Table from '../../shared/Table'
import TableContent from '../../shared/TableContent'
import Lang from '../../../util/lang'

const TitleRow = styled(Row)`
  width: 100%;
`

const ProductList = () => {
  const [products, setProducts] = useState([])

  useEffect(async () => {
    // const productsFound = await (await fetch(process.env.REACT_APP_products_URL)).json()
    // setProducts({ products: productsFound })
    setProducts([
      {
        id: 1,
        created: '04/23/2020',
        name: 'Platinum',
        product: 'SMS_ALERTS',
        velocity: 0.33,
      },
    ])
  }, [])

  return (
    <Container>
      <Column>
        <TitleRow>
          <Title>Products</Title>
        </TitleRow>
        <Table
          headers={Lang.admin.productFields}
          tableContent={<TableContent rowItems={products} />}
        />
      </Column>
    </Container>
  )
}
export default ProductList
