import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GiBull } from 'react-icons/gi'
import { NavLink } from './NavbarElements'

const LogoWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin-left: 3%;
`

const LinkWrapper = styled(NavLink)`
  text-decoration: none;
  color: black;
  border: green;
`

const Logo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: xx-large;
`

const LogoLabel = styled.span`
  font-size: 2.1rem;
  font-weight: 800;
`

const AppLogo = (props) => {
  const { closeMenu } = props

  return (
    <LogoWrapper>
      <LinkWrapper to='/' end onClick={closeMenu}>
        <Logo>
          <GiBull />
          <LogoLabel>BidBull</LogoLabel>
        </Logo>
      </LinkWrapper>
    </LogoWrapper>
  )
}

AppLogo.propTypes = {
  closeMenu: PropTypes.func.isRequired,
}

export default AppLogo
