import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im'
import { Column } from '../shared/layout'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'

const TestimonialContainer = styled.div`
  display: flex;
  padding: 2% 15%;
`

const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  img {
    border-radius: 50px;
  }
`

const AuthorName = styled.div`
  padding: 2%;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
`

const Comment = styled.div`
  display: flex;
  flex-wrap: row nowrap;
  justify-content: center;
  padding: 3%;
  font-size: 1.4rem;
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    font-size: 1.1rem;
  }
`

const CommentText = styled.span`
  line-height: 1.4rem;
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    line-height: 1.1rem;
  }
`

function TestimonialCard({ name, thumbnailPic, comment }) {
  return (
    <TestimonialContainer data-testid='testimonial-card'>
      <Column>
        <Thumbnail data-testid='testimonial-thumbnail'>
          <img src={thumbnailPic} height='50px' width='50px' alt='testimonial-thumbnail' />
        </Thumbnail>
        <Comment data-testid='testimonial-comment'>
          <CommentText>
            <ImQuotesLeft /> {comment} <ImQuotesRight />
          </CommentText>
        </Comment>
        <AuthorName data-testid='testimonial-author'>{`-${name}`}</AuthorName>
      </Column>
    </TestimonialContainer>
  )
}

TestimonialCard.propTypes = {
  thumbnailPic: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
}

export default TestimonialCard
