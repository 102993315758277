import React from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'
import { Container } from './layout'

function Tab(props) {
  const { isSelected, children } = props

  if (isSelected) {
    return <Container>{children}</Container>
  }
  return null
}

Tab.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
}

export default Tab
