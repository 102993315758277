import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

const Cell = styled.td`
  text-align: left;
  padding: 1.2rem 1.6rem;
  border-top: 1px solid #ccc;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
`

const TableRow = styled.tr`
  font-weight: 600;
  &:hover td {
    border-top: 1px solid #11bb22;
    border-bottom: 1px solid #11bb22;
  }
`

const TableContent = (props) => {
  const { rowItems } = props
  return (
    <tbody>
      {rowItems.map((item) => {
        const itemEntries = Object.entries(item)
        return (
          <TableRow key={uuid().substr(0, 10)} data-testid='table-row'>
            {itemEntries.map(([field, val]) => (
              <Cell key={field} data-testid={`table-${field}-cell`}>
                {val}
              </Cell>
            ))}
          </TableRow>
        )
      })}
    </tbody>
  )
}

TableContent.propTypes = {
  rowItems: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default TableContent
