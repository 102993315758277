import React, { useEffect, useReducer, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
// creates new unique uuid
import { v4 as uuid } from 'uuid'
// import mutation and query
import { listTrades as ListTrades, listComments as ListComments } from '../../../graphql/queries'
import {
  onCreateTrade as OnCreateTrade,
  onCreateComment as OnCreateComment,
} from '../../../graphql/subscriptions'
import { Row } from '../../shared/layout'
import TabNav from '../../shared/TabNav'
import Tab from '../../shared/Tab'
import Table from '../../shared/Table'
import TableContent from '../../shared/TableContent'
import Lang from '../../../util/lang'
import {
  SET_TRADE_ALERTS,
  SET_COMMENT_ALERTS,
  ADD_TRADE_ALERT,
  ADD_COMMENT_ALERT,
} from '../../../util/actionTypes'
import alertsReducer, { initialState } from '../../../reducers/alerts.reducer'

const CLIENT_ID = uuid()

function AlertsList() {
  const [state, dispatch] = useReducer(alertsReducer, initialState)
  const [selected, setSelected] = useState(Lang.admin.alertTabs[0])

  async function getAlerts() {
    try {
      const apiTradeData = await API.graphql(graphqlOperation(ListTrades))
      console.log('TRADE DATA: ', apiTradeData)
      dispatch({ type: SET_TRADE_ALERTS, trades: apiTradeData.data.listTrades.items })
      const apiCommentData = await API.graphql(graphqlOperation(ListComments))
      console.log('COMMENT DATA: ', apiCommentData)
      dispatch({ type: SET_COMMENT_ALERTS, comments: apiCommentData.data.listComments.items })
    } catch (err) {
      console.log('ERROR fetching alerts...', err)
    }
  }

  useEffect(() => {
    getAlerts()
    const tradeSub = API.graphql(graphqlOperation(OnCreateTrade)).subscribe({
      next: (eventData) => {
        const trade = eventData.value.data.onCreateTrade
        if (alert.clientId === CLIENT_ID) return
        dispatch({ type: ADD_TRADE_ALERT, trade })
      },
    })
    const commentSub = API.graphql(graphqlOperation(OnCreateComment)).subscribe({
      next: (eventData) => {
        const comment = eventData.value.data.onCreateComment
        if (alert.clientId === CLIENT_ID) return
        dispatch({ type: ADD_COMMENT_ALERT, comment })
      },
    })
    return () => {
      tradeSub.unsubscribe()
      commentSub.unsubscribe()
    }
  }, [])

  return (
    <Row>
      <TabNav tabs={Lang.admin.alertTabs} selected={selected} setSelected={setSelected}>
        <Tab isSelected={selected === 'Trade'}>
          <Table
            headers={Lang.admin.alerts.tradeFields}
            tableContent={<TableContent rowItems={state.trades} />}
          />
        </Tab>
        <Tab isSelected={selected === 'Comment'}>
          <Table
            headers={Lang.admin.alerts.commentFields}
            tableContent={<TableContent rowItems={state.comments} />}
          />
        </Tab>
      </TabNav>
    </Row>
  )
}

export default AlertsList
