import React from 'react'
import { Container, Column, SectionTitle, CenteredContent } from '../components/shared/layout'

const Disclaimer = () => (
  <Container>
    <Column>
      <CenteredContent>
        <SectionTitle>Disclaimer</SectionTitle>
      </CenteredContent>

      <p>
        Bid Bull alerts/reports/releases/profiles are for general information and educational
        purposes only. We are engaged in the business of education unless otherwise stated.
      </p>

      <p>
        PLEASE NOTE WELL: Bid Bull and its employees are not a Registered Investment Advisor, Broker
        Dealer or a member of any association for other research providers in any jurisdiction
        whatsoever and we are not qualified to give financial advice. BIDBULL.CO WILL NEVER ACCEPT
        FREE OR RESTRICTED TRADING SHARES IN ANY COMPANIES MENTIONED at BIDBULL.CO &/OR ANY OF OUR
        SOCIAL NETWORKING AND EMAIL ADVERTISING PLATFORMS.
      </p>

      <p>
        Our website and newsletter are for entertainment purposes only. This newsletter is NOT a
        source of unbiased information. Never invest in any stock featured on our site or emails
        unless you can afford to lose your entire investment. Gains mentioned in our newsletter and
        on our website may be based on End of Day or intraday data. The disclaimer is to be read and
        fully understood before using our site, or joining our email list. Full disclaimer can be
        read at https://www.bidbull.co/disclaimer
      </p>
      <p>
        We encourage all to read the <span>SEC’s INVESTOR ALERT</span> before reading our
        Newsletter.
      </p>

      <p>
        Release of Liability: Through use of this email and/or website advertisement viewing or
        using you agree to hold Bidbull.co, its operators owners and employees harmless and to
        completely release them from any and all liability due to any and all loss (monetary or
        otherwise), damage (monetary or otherwise), or injury (monetary or otherwise) that you may
        incur. bidbull.co alerts, emails, reports and or newsletters do not purport to provide an
        analysis of any company’s financial position, operations or prospects and this is not to be
        construed as a recommendation by bidbull.co or an offer or solicitation to buy or sell any
        security.
      </p>

      <p>
        None of the materials or advertisements in our coverage constitute offers or solicitations
        to purchase or sell securities of the companies profiled herein and any decision to invest
        in any such company or other financial decisions should not be made based upon the
        information provide herein. Instead Bidbull.co strongly urges you conduct a complete and
        independent investigation of the respective companies and consideration of all pertinent
        risks. Readers are advised to review SEC periodic reports: Forms 10-Q, 10K, Form 8-K,
        insider reports, Forms 3, 4, 5 Schedule 13D. Bidbull.co is compliant with the Can Spam Act
        of 2003. Bidbull.co does not offer such advice or analysis, and Bidbull.co further urges you
        to consult your own independent tax, business, financial and investment advisors. Investing
        in micro-cap and growth securities is highly speculative and carries and extremely high
        degree of risk. It is possible that an investor’s investment may be lost or impaired due to
        the speculative nature of the companies profiled.
      </p>

      <p>
        The Private Securities Litigation Reform Act of 1995 provides investors a ‘safe harbor’ in
        regard to forward-looking statements. Any statements that express or involve discussions
        with respect to predictions, expectations, beliefs, plans, projections, objectives, goals,
        assumptions or future events or performance are not statements of historical fact may be
        “forward looking statements”. Forward looking statements are based on expectations,
        estimates, and projections at the time the statements are made that involve a number of
        risks an uncertainties which could cause actual results or events to differ materially from
        those presently anticipated. Forward looking statements in this action may be identified
        through use of words such as “projects”, “foresee”, “expects”, “will”, “anticipates”,
        “estimates”, “believes”, “understands”, or that by statements indicating certain actions
        “may”, “could”, or “might” occur. Understand there is no guarantee past performance will be
        indicative of future results. Past Performance is based on the security’s previous day
        closing price and the high of day price during our promotional coverage.
      </p>
    </Column>
  </Container>
)

export default Disclaimer
