// Responsive breakpoints
export const PHONE_UPPER_BOUND = '600px'
export const TABLET_PORTRAIT_UPPER_BOUND = '900px'
export const TABLET_LANDSCAPE_UPPER_BOUND = '1200px'
export const DESKTOP_UPPER_BOUND = '1800px'
// TODO: move URLs to .env file and update refs
export const LOGIN_URL = 'https://bidbull-api.com/login'
export const SUBSCRIBE_URL = 'https://bidbull-api.com/subscribe'
export const GET_ALERTS_URL = 'https://bidbull-api.com/alerts'
export const GET_SUBSCRIPTIONS_URL = 'https://bidbull-api.com/subscriptions'
export const GET_ACCOUNTS_URL = 'https://bidbull-api.com/accounts'
export const LONG = 'Long'
export const SHORT = 'Short'
export const OPEN = 'Open'
export const CLOSE = 'Close'
export const TRADE = 'Trade'
export const COMMENT = 'Comment'
