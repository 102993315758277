// ACTIONS
export const SET_TRADE_ALERTS = 'SET_TRADE_ALERTS'
export const SET_COMMENT_ALERTS = 'SET_COMMENT_ALERTS'
export const ADD_TRADE_ALERT = 'ADD_TRADE_ALERT'
export const ADD_COMMENT_ALERT = 'ADD_COMMENT_ALERT'
export const SET_TRADE_INPUT = 'SET_TRADE_INPUT'
export const SET_COMMENT_INPUT = 'SET_COMMENT_INPUT'
export const CLEAR_TRADE_INPUT = 'CLEAR_TRADE_INPUT'
export const CLEAR_COMMENT_INPUT = 'CLEAR_COMMENT_INPUT'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_CUSTOMERS = 'SET_CUSTOMERS'
