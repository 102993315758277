import React from 'react'
import styled from 'styled-components'
import Slide from 'react-reveal/Slide'
import SubscribeForm from './SubscribeForm'
import Lang from '../../util/lang'

const Section = styled.section`
  /* margin-bottom: 70px; */
`

const Background = styled.div`
  min-height: 500px;
  height: 90vh;
  position: relative;
  background-image: url('../assets/nyse-with-head.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: white;
`

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SubscribeText = styled.div`
  text-align: center;
`

const Title = styled.h2`
  text-transform: uppercase;
  font-size: 3rem;
  color: white;
  text-shadow: 1px 2px 1px #333;
`

const SubTitle = styled.p`
  display: flex;
  font-size: 1.8rem;
  color: #ddd;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-shadow: 2px 2px 2px #777;
`

const Emphasis = styled.span`
  color: #11bb11;
  font-weight: 800;
  text-transform: uppercase;
  text-shadow: 1px 2px 1px #444;
`

const CallToSubscribe = () => (
  <Section data-testid='subscribe-section'>
    <Background className='container' data-testid='subscribe-container'>
      <Overlay>
        <SubscribeText data-testid='subscribe-text'>
          <Slide left>
            <Title data-testid='subscribe-title'>
              Are You Ready to be a <Emphasis>Bid Bull</Emphasis>?!
            </Title>
          </Slide>
          <Slide left delay={200}>
            <SubTitle data-testid='subscribe-subtitle'>{Lang.subscribe.subtitle}</SubTitle>
          </Slide>
        </SubscribeText>
        <SubscribeForm />
      </Overlay>
    </Background>
  </Section>
)

export default CallToSubscribe
