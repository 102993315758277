import React from 'react'
import PropTypes from 'prop-types'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa'
import styled from 'styled-components'
import { CenteredContent, Container, Column } from '../shared/layout'
import { NavLink } from '../Header/NavbarElements'

const FooterSection = styled.footer`
  background-color: #eee;
  padding-top: 5%;
`

const Menu = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

const MenuList = styled.ul`
  list-style: disc;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

const MenuListItem = styled.li`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &:after {
    background: url('../assets/point.png') no-repeat right top;
    content: '';
    height: 4px;
    position: absolute;
    right: -1px;
    top: 8px;
    width: 4px;
  }
`

const MenuLink = styled(NavLink)`
  /* color: #000; */
  background: inherit;
  text-transform: uppercase;
  border-top: 2px solid transparent;
  font-size: 0.9rem;
  &.active {
    /* border-top: 2px solid #11bb11; */
    font-weight: 800;
    border-bottom: 2px solid transparent;
  }
`

const LegalText = styled.div`
  text-align: center;
  font-size: 0.6rem;
  color: #666;
`

const SocialLinksSection = styled(Column)`
  padding: 5%;
`

const SocialLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const SocialLinksTitle = styled(CenteredContent)`
  width: 100%;
  font-size: 0.9rem;
`

const SocialIcon = styled.div`
  padding: 2%;
`

const NameSpan = styled.span`
  color: #000;
  padding: 0 0.3%;
`

const DisclaimerLink = styled.a`
  text-decoration: none;
  color: #11bb11;
  padding: 0 0.4%;
`

const Footer = ({ isLoggedIn }) => (
  <FooterSection>
    <Container>
      <Menu>
        <MenuList>
          <MenuListItem>
            <MenuLink to='/' end data-testid='footer-home-link'>
              HOME
            </MenuLink>
          </MenuListItem>
          <MenuListItem>
            <MenuLink to='/about' data-testid='footer-about-link'>
              ABOUT
            </MenuLink>
          </MenuListItem>
          <MenuListItem>
            <MenuLink to='/contact' data-testid='footer-contact-link'>
              CONTACT
            </MenuLink>
          </MenuListItem>
          <MenuListItem>
            <MenuLink to='/pricing' data-testid='footer-pricing-link'>
              PRICING
            </MenuLink>
          </MenuListItem>
          {isLoggedIn ? (
            <>
              <MenuListItem>
                <MenuLink to='/logout' data-testid='footer-logout-link'>
                  Logout
                </MenuLink>
              </MenuListItem>
            </>
          ) : (
            <>
              <MenuListItem>
                <MenuLink to='/login' data-testid='footer-login-link'>
                  Login
                </MenuLink>
              </MenuListItem>
              <MenuListItem>
                <MenuLink to='/signup' data-testid='footer-signup-link'>
                  Signup
                </MenuLink>
              </MenuListItem>
            </>
          )}
        </MenuList>
      </Menu>

      <hr />

      <LegalText>
        <p>
          © Copyright 2019 <NameSpan>Bid Bull</NameSpan>
          All Rights Reserved
          <DisclaimerLink href='/disclaimer'> Disclaimer </DisclaimerLink>
        </p>
      </LegalText>

      <SocialLinksSection>
        <SocialLinksTitle>Follow Us</SocialLinksTitle>
        <SocialLinks>
          <SocialIcon>
            <a href='https://stocktwits.com'>
              <img
                src='../assets/icon-stocktwits.webp'
                width='25px'
                height='25px'
                alt='stocktwits'
              />
            </a>
          </SocialIcon>
          <SocialIcon>
            <a href='https://facebook.com'>
              <FaFacebookF />
            </a>
          </SocialIcon>
          <SocialIcon>
            <a href='https://twitter.com'>
              <FaTwitter />
            </a>
          </SocialIcon>
          <SocialIcon>
            <a href='https://linkedin.com'>
              <FaLinkedinIn />
            </a>
          </SocialIcon>
        </SocialLinks>
      </SocialLinksSection>
    </Container>
  </FooterSection>
)

Footer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
}

export default Footer
