import React from 'react'
import styled from 'styled-components'
import Slide from 'react-reveal/Slide'
import Roll from 'react-reveal/Roll'
import { GiPieChart } from 'react-icons/gi'
import SectorBox from './SectorBox'
import { Container, Column, Emphasis, SectionTitle } from '../shared/layout'
// import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'
import sectorListData from './sectorListData'

const SectorContainer = styled(Container)`
  background-color: #444;
  height: 90%;
`

const SectorTitle = styled(SectionTitle)`
  font-size: 3rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 2px 2px #333;
`

const SectorColumn = styled(Column)`
  justify-content: stretch;
  align-items: stretch;
`

const SectorContent = styled.div`
  padding-bottom: 8%;
`

const SectorList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
`

const Sectors = () => (
  <section data-testid='sectors-section'>
    <SectorContainer data-testid='sectors-container'>
      <SectorColumn>
        <Slide left>
          <SectorTitle data-testid='sectors-title'>
            <GiPieChart />
            <Emphasis> Sectors</Emphasis> We Cover
          </SectorTitle>
        </Slide>
        <SectorContent data-testid='sectors-content'>
          <SectorList data-testid='sectors-list'>
            {sectorListData.map((sector, i) => (
              <Roll left delay={(i + 1) * 200} key={sector.name}>
                <SectorBox key={sector.name} name={sector.name} icon={sector.icon} />
              </Roll>
            ))}
          </SectorList>
        </SectorContent>
      </SectorColumn>
    </SectorContainer>
  </section>
)

export default Sectors
