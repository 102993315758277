import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import withReveal from 'react-reveal/withReveal'
// import Reveal from 'react-reveal/Reveal'
import { PHONE_UPPER_BOUND, TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'
import Lang from '../../util/lang'

const Ticker = styled.div`
  margin: 2%;
  font-size: xx-large;
  color: #f57e1d;
`

const GainPercent = styled.div`
  font-size: small;
  font-weight: 800;
  margin: 1%;
`

const PriceRange = styled.div`
  font-size: small;
  margin: 1%;
`

const ViewChartButton = styled.div`
  padding: 3% 5%;
  margin: 3%;
  border-radius: 3px;
  color: white;
  background-color: #11bb11;
  cursor: pointer;
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* inline-block; */
  /* text-align: center; */
  padding: 3%;
  border: 1px solid grey;
  width: calc(50% - 3rem);
  &:hover {
    color: #121212;
    background-color: #11bb11;
  }
  &:hover ${Ticker} {
    color: #792200;
  }
  &:hover ${ViewChartButton} {
    background-color: #118811;
  }
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: calc(50% - 3rem);
  }

  @media only screen and (max-width: ${PHONE_UPPER_BOUND}) {
    width: 100%;
  }
`

const WinnerBox = ({ ticker, gain, range }) => (
  <BoxContainer data-testid='winner-box'>
    <Ticker data-testid='winner-ticker'>{ticker}</Ticker>
    <GainPercent data-testid='winner-gain'>{gain} Gains</GainPercent>
    <PriceRange data-testid='winner-price-range'>{range}</PriceRange>
    <ViewChartButton data-testid='view-chart-btn'>{Lang.winners.viewChartButton}</ViewChartButton>
  </BoxContainer>
)

WinnerBox.propTypes = {
  ticker: PropTypes.string.isRequired,
  gain: PropTypes.string.isRequired,
  range: PropTypes.string.isRequired,
}

export default WinnerBox
