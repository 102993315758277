import React from 'react'
import { GoMailRead } from 'react-icons/go'
import { GiVibratingSmartphone } from 'react-icons/gi'
import { IoRocketOutline } from 'react-icons/io5'

const Lang = {
  admin: {
    actions: ['alerts', 'subscribers', 'billing'],
    alerts: {
      tradeFields: ['date', 'ticker', 'direction', 'quantity', 'price', 'leg'],
      commentFields: ['date', 'comment'],
    },
    productFields: ['id', 'created', 'name', 'type', 'velocity'],
    customerFields: ['accountId', 'created', 'userId', 'billing_cycle', 'receivable'],
    alertTabs: ['Trade', 'Comment'],
  },
  hero: {
    title: 'Welcome to Bid Bull',
    subtitle:
      'Exclusive stock alerts from our proprietary algorithm delivered right to your inbox for only $74 a month - Cancel anytime!',
    callToAction: 'Start your 7-day free trial',
  },
  testimonials: {
    title: 'What our subscribers say',
  },
  countdown: {
    headline: 'Our next explosive stock alert is coming in...',
  },
  howItWorks: {
    title: 'How It Works',
    subtitle: 'Sign up in less than 5 minutes with our easy 3-step process!',
    steps: [
      { icon: <GoMailRead />, text: 'Subscribe to our Stock Alert service' },
      { icon: <GiVibratingSmartphone />, text: 'Receive our Stock Alerts via SMS or Email' },
      { icon: <IoRocketOutline />, text: 'Start trading like a pro!' },
    ],
    subscribeCall: 'Subscribe NOW',
  },
  winners: {
    title: 'Recent Winners',
    subtitle: '“It takes 20 years to build a reputation…',
    viewChartButton: 'View Chart',
    content: [
      '….and five minutes to ruin it. If you think about that you’ll do things differently.” – Legendary Investor, Warren Buffett',
      'Our track record is our reputation. And you can be sure we don’t take it lightly.',
      'That’s why we strive to be the To Financial Newsletter on the Street Today.',
      'Reliability. Accountability. Transparency.',
      '',
    ],
  },
  pennyStocks: {
    title: 'Why Penny Stocks?',
  },
  subscribe: {
    title: 'Are You Ready To Be A Bid Bull?!',
    subtitle: 'Put us to the test and unsubscribe anytime!',
  },
}

export default Lang
