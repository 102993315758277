import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container } from './layout'

const TabList = styled.ul``

const TabListItem = styled.li``

const TabLink = styled.a``

function TabNav(props) {
  const { tabs, selected, setSelected, children } = props

  return (
    <Container>
      <TabList>
        {tabs.map((tab) => {
          const active = tab === selected ? 'active ' : ''
          return (
            <TabListItem key={tab}>
              <TabLink isActive={active} onClick={() => setSelected(tab)}>
                {tab}
              </TabLink>
            </TabListItem>
          )
        })}
      </TabList>
      {children}
    </Container>
  )
}

TabNav.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default TabNav
