import React from 'react'
import styled from 'styled-components'
import Slide from 'react-reveal/Slide'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Container, ResponsiveContent } from '../shared/layout'
import Counter from './Counter'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'

const CountdownContent = styled(ResponsiveContent)`
  justify-content: stretch;
  align-items: center;
  color: #fff;
`

const CountdownHeadline = styled.div`
  padding: 2%;
  background: #333;
  font-weight: 700;
  font-size: 2.8rem;
  text-transform: uppercase;
  text-align: left;
  line-height: 5rem;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 50%;
  }
`

const Strong = styled.strong`
  text-transform: uppercase;
  color: #11bb11;
`

const Countdown = () => (
  <section data-testid='countdown-section'>
    <ScrollableAnchor id='countdown'>
      <Container data-testid='countdown-container'>
        <CountdownContent data-testid='countdown-content'>
          <CountdownHeadline data-testid='countdown-headline'>
            <Slide left>
              <div>
                Our next <Strong>explosive</Strong>
              </div>
            </Slide>
            <Slide left delay={300}>
              <div> stock alert is </div>
            </Slide>
            <Slide left delay={600}>
              <div>coming in...</div>
            </Slide>
          </CountdownHeadline>
          <Counter />
        </CountdownContent>
      </Container>
    </ScrollableAnchor>
  </section>
)

export default Countdown
