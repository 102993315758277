import { FaBars } from 'react-icons/fa'
import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'

export const Nav = styled.nav`
  background: #fff;
  height: 85px;
  display: flex;
  flex: 1 2 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.2rem calc((100v2 - 1000px) / 2);
  z-index: 12;
`

export const NavLink = styled(Link)`
  color: #808080;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  text-indent: 0.3rem;
  padding: 0.3rem 1rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  &.active {
    color: #333;
    /* background: #11bb11; */
    border-bottom: 2px solid #11bb11;
    transition: all 0.5s ease-in-out;
  }
  &.hover {
    transform: translateY(-2px);
  }
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    align-items: center;
    font-size: 3rem;
  }
`

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  margin-right: 3%;
  list-style: none;
  @media screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 90%;
    height: 100vh;
    top: 54px;
    left: 0;
    font-size: 3em;
    padding: 5%;
    background: #fff;
    transform: ${(props) => (props.showMenu ? 'translateY(0)' : 'translateY(-150%)')};
    transition: 0.7s all ease-in-out;
    z-index: 200;
    box-shadow: 0px 1px 2px hsl(0deg 0% 8% / 9%), 0px 1px 2.75px hsl(0deg 0% 8%/9%),
      0px 1px 4px hsl(0deg 0% 8%/9%);
  }
`

export const NavListItem = styled.li`
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    display: flex;
    width: 80%;
    padding: 5%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    display: none;
  }
`

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #11bb11;
  padding: 10px 22px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    background: #78ef19;
    color: #404040;
  }
`
