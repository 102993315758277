import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
// import Slide from 'react-reveal/Slide'
import { Button } from '../shared/interactive'
import { TABLET_PORTRAIT_UPPER_BOUND, SUBSCRIBE_URL } from '../../util/constants'

const FormWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  @media only scren and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 90%;
    flex-flow: column nowrap;
  }
`

const ResponsiveForm = styled.form`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  margin-bottom: 3%;
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    flex-flow: column nowrap;
  }
`

const SubscribeInput = styled.input`
  box-sizing: border-box;
  height: 4rem;
  border: 1px solid #555;
  background: #fff;
  color: #000;
  padding: 0 2%;
  font-size: 1.3rem;
  margin-right: 2%;
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 100%;
    margin-bottom: 2%;
    margin-right: 0;
    font-size: 1.8rem;
  }
`

const SubmitButton = styled(Button)`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 4%;
  color: white;
  background-color: #11bb11;
  font-size: 1.3rem;
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    padding: 4% 6%;
    width: 100%;
    font-size: 1.8rem;
  }
`

const initialState = {
  name: '',
  email: '',
}

const SubscribeForm = () => {
  const [state, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.currentTarget
    setState((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('SUBMITTED: ', state)
    // if (!e.target.checkValidity()) {
    //   console.log('NOT VALID')
    //   return
    // }
    const regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    const test = regex.test(state.email)
    console.log(test)

    // const data = new FormData(state)
    // console.log(data)
    axios
      .post(SUBSCRIBE_URL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      })
      .then((response) => {
        console.log('SUCCEEDED: ', response)
        setState(initialState)
      })
      .catch((error) => {
        if (error.response) {
          // When response status code is out of 2xx range
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // When no response was recieved after request was made
          console.log(error.request)
        } else {
          // Error
          console.log(error.message)
        }
      })
  }

  return (
    <FormWrapper data-testid='subscribe-form-wrapper'>
      <ResponsiveForm noValidate onSubmit={handleSubmit} data-testid='subscribe-form'>
        {/* <Slide right big delay={400}> */}
        <SubscribeInput
          name='name'
          type='text'
          value={state.name}
          placeholder='Name'
          onChange={handleChange}
          pattern='\w+'
          required
          data-testid='subscribe-form-name-input'
        />
        {/* </Slide> */}
        {/* <Slide right big delay={400}> */}
        <SubscribeInput
          name='email'
          type='email'
          value={state.email}
          placeholder='Email'
          onChange={handleChange}
          required
          pattern={/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/}
          data-testid='subscribe-form-email-input'
        />
        {/* </Slide> */}
        {/* <Slide right big delay={400}> */}
        <SubmitButton value='submit' type='submit' data-testid='subscribe-form-submit-btn'>
          {'Subscribe'.toUpperCase()}
        </SubmitButton>
        {/* </Slide> */}
      </ResponsiveForm>
    </FormWrapper>
  )
}

export default SubscribeForm
