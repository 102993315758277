import React from 'react'
import Home from '../pages/Home'
import About from '../pages/About'
// import Products from '../pages/Products'
import Pricing from '../pages/Pricing'
import Blog from '../pages/Blog'
import Contact from '../pages/Contact'
import Signup from '../pages/Signup'
// import Login from '../pages/Login'
import Disclaimer from '../pages/Disclaimer'
import Admin from '../pages/Admin'
import Dashboard from '../pages/Dashboard'
import Alerts from '../components/Admin/Alerts/Alerts'
import AlertsList from '../components/Admin/Alerts/AlertsList'
import SendAlert from '../components/Admin/Alerts/SendAlert'
import ProductList from '../components/Admin/Products/ProductList'
import Customers from '../components/Admin/Customers/Customers'

// TODO: implement auth requirement
// function requireAuth(nextState, replaceState) {
// if (!auth.loggedIn()) {
// replaceState({ nextPathname: nextState.location.pathname }, '/login')
//   // replaceState({
//   //   pathname: '/login',
//   //   state: { nextPathname: nextState.location.pathname },
//   // })
// }
// }

const AppRoutes = [
  { path: '/', element: <Home /> },
  { path: '/about', element: <About /> },
  // { path: '/products', element: <Products /> },
  { path: '/pricing', element: <Pricing /> },
  { path: '/blog', element: <Blog /> },
  { path: '/contact', element: <Contact /> },
  { path: '/signup', element: <Signup /> },
  // { path: '/login', element: <Login /> },
  { path: '/disclaimer', element: <Disclaimer /> },
  { path: '/dashboard', element: <Dashboard /> },
  {
    path: '/admin',
    element: <Admin />,
    children: [
      {
        path: '/alerts',
        element: <Alerts />,
        children: [
          { path: '/', element: <AlertsList /> },
          { path: '/send', element: <SendAlert /> },
        ],
      },
      { path: '/products', element: <ProductList /> },
      { path: '/customers', element: <Customers /> },
    ],
  },
]

export default AppRoutes
