import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import TabNav from '../../shared/TabNav'
import Tab from '../../shared/Tab'
import TradeAlert from './TradeAlert'
import CommentAlert from './CommentAlert'
import { Container } from '../../shared/layout'
import Lang from '../../../util/lang'

const CLIENT_ID = uuid()

function SendAlert() {
  const [selected, setSelected] = useState(Lang.admin.alertTabs[0])

  return (
    <Container>
      <TabNav tabs={Lang.admin.alertTabs} selected={selected} setSelected={setSelected}>
        <Tab isSelected={selected === 'Trade'}>
          <TradeAlert clientId={CLIENT_ID} />
        </Tab>
        <Tab isSelected={selected === 'Comment'}>
          <CommentAlert clientId={CLIENT_ID} />
        </Tab>
      </TabNav>
    </Container>
  )
}

export default SendAlert
