import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import makeCarousel from 'react-reveal/makeCarousel'
import Slide from 'react-reveal/Slide'
import testimonialData from './testimonialData'
import TestimonialCard from './TestimonialCard'

const width = '90%'
const height = '300px'
const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: ${width};
`
const Children = styled.div`
  width: 100%;
  position: relative;
  height: ${height};
`
const Arrow = styled.div`
  text-shadow: 1px 1px 1px #fff;
  z-index: 100;
  line-height: ${height};
  text-align: center;
  position: absolute;
  top: 0;
  width: 10%;
  font-size: 3em;
  cursor: pointer;
  user-select: none;
  ${(props) =>
    props.right
      ? css`
          left: 90%;
        `
      : css`
          left: 0%;
        `}
`
const Dot = styled.span`
  font-size: 1.5em;
  color: #11bb11;
  cursor: pointer;
  text-shadow: 1px 1px 1px #fff;
  user-select: none;
`
const Dots = styled.span`
  display: inline-block;
  text-align: center;
  width: 100%;
  z-index: 100;
`

const CarouselUI = ({ position, total, handleClick, children }) => (
  <Container data-testid='carousel-container'>
    <Children data-testid='carousel-children'>
      {children}
      <Arrow onClick={handleClick} data-position={position - 1} data-testid='carousel-arrow-left'>
        {'<'}
      </Arrow>
      <Arrow
        right
        onClick={handleClick}
        data-position={position + 1}
        data-testid='carousel-arrow-right'
      >
        {'>'}
      </Arrow>
    </Children>
    <Dots data-testid='carousel-dots'>
      {Array(...Array(total)).map((val, index) => (
        // eslint-disable-next-line
        <Dot key={index} onClick={handleClick} data-position={index} data-testid='carousel-dot'>
          {index === position ? '● ' : '○ '}
        </Dot>
      ))}
    </Dots>
  </Container>
)
const Carousel = makeCarousel(CarouselUI)

CarouselUI.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  position: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
}

const TestimonialsCarousel = () => (
  <Carousel data-testid='testimonials-carousel'>
    {testimonialData.map((testimonial) => (
      <Slide right key={testimonial.author} data-testid='carousel-slide'>
        <TestimonialCard
          key={testimonial.author}
          name={testimonial.author}
          thumbnailPic={testimonial.thumbnail}
          comment={testimonial.comment}
        />
      </Slide>
    ))}
  </Carousel>
)

export default TestimonialsCarousel
