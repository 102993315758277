import React from 'react'
import styled from 'styled-components'
import { GiTwoCoins } from 'react-icons/gi'
import withReveal from 'react-reveal/withReveal'
import Slide from 'react-reveal/Slide'
import { Container, Column, SectionTitle, Emphasis } from '../shared/layout'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'
import pennystocksData from './pennystocksData'

const PennyStocksSection = styled.section`
  background-color: #f57e1d;
  color: whitesmoke;
`

const PennyContainer = styled(Container)`
  padding-left: 6%;
  padding-right: 6%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    flex-direction: row;
  }
`

const TextContent = styled.div`
  width: 100%;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 70%;
    font-size: 1.1rem;
  }
`

const PennyTitle = withReveal(
  styled(SectionTitle)`
    font-size: 3rem;
  `,
  <Slide left />
)

const PennyImage = styled.div`
  width: 100%;
  height: auto;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: calc(30% - 2rem);
    /* height: 80%; */
  }
`

const PennyList = styled.div``

const PennyTextBlock = styled.p``

const PennyStocks = () => (
  <PennyStocksSection data-testid='penny-stocks-section'>
    <PennyContainer data-testid='penny-stocks-container'>
      <TextContent>
        <Column data-testid='penny-stocks-title'>
          <PennyTitle>
            <GiTwoCoins /> Why <Emphasis>Penny </Emphasis>Stocks?
          </PennyTitle>

          <PennyList data-testid='penny-stocks-list'>
            {pennystocksData.map((textBlock, i) => (
              <Slide left delay={(i + 1) * 200} key={textBlock.substr(0, 7)}>
                <PennyTextBlock key={textBlock.substr(0, 7)} data-testid='penny-stocks-list-item'>
                  {textBlock}
                </PennyTextBlock>
              </Slide>
            ))}
          </PennyList>
        </Column>
      </TextContent>

      <PennyImage data-testid='penny-stocks-image'>
        <img src='../assets/penny-stock-icon.png' width='100%' height='100%' alt='pennies' />
      </PennyImage>
    </PennyContainer>
  </PennyStocksSection>
)

export default PennyStocks
