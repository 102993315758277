const winnersListData = [
  {
    ticker: 'GBSN',
    gain: '212% GAINS',
    range: 'From 1.95 to 6.10',
  },
  {
    ticker: 'TASR',
    gain: '343% GAINS',
    range: 'From 8.10 to 35.95',
  },
  {
    ticker: 'ABWN',
    gain: '300% GAINS',
    range: 'From 0.65 to 2.60',
  },
  {
    ticker: 'SALT',
    gain: '114% GAINS',
    range: 'From 3.65 to 7.82',
  },
]

export default winnersListData
