import React from 'react'
import PropTypes from 'prop-types'
import { Nav, NavListItem, NavLink, Bars, NavMenu, NavBtn, NavBtnLink } from './NavbarElements'

const Navbar = ({ isLoggedIn, isAdmin, handleClick, showMenu, closeMenu }) => (
  <Nav>
    <Bars onClick={() => handleClick()} />
    <NavMenu showMenu={showMenu}>
      {isAdmin && (
        <NavListItem>
          <NavLink
            to='/admin'
            end
            data-testid='navbar-admin-link'
            // activeStyle
            onClick={closeMenu}
          >
            Admin
          </NavLink>
        </NavListItem>
      )}
      <NavListItem>
        <NavLink
          to='/'
          end
          data-testid='navbar-home-link'
          // activeStyle
          onClick={closeMenu}
        >
          Home
        </NavLink>
      </NavListItem>
      <NavListItem>
        <NavLink
          to='/about'
          data-testid='navbar-about-link'
          // activeStyle
          onClick={closeMenu}
        >
          About
        </NavLink>
      </NavListItem>
      <NavListItem>
        <NavLink
          to='/contact'
          data-testid='navbar-contact-link'
          // activeStyle
          onClick={closeMenu}
        >
          Contact
        </NavLink>
      </NavListItem>
      <NavListItem>
        <NavLink
          to='/pricing'
          data-testid='navbar-pricing-link'
          // activeStyle
          onClick={closeMenu}
        >
          Pricing
        </NavLink>
      </NavListItem>
      {isLoggedIn ? (
        <>
          <NavListItem>
            <NavBtn>
              <NavBtnLink
                to='/logout'
                data-testid='navbar-logout-link'
                onClick={() => {
                  window.localStorage.removeItem('token')
                  // history.push('/')
                  closeMenu()
                }}
              >
                Logout
              </NavBtnLink>
            </NavBtn>
          </NavListItem>
        </>
      ) : (
        <>
          <NavListItem>
            <NavBtn>
              <NavBtnLink to='/login' data-testid='navbar-login-link' onClick={closeMenu}>
                Login
              </NavBtnLink>
            </NavBtn>
          </NavListItem>
          <NavListItem>
            <NavBtn>
              <NavBtnLink to='/signup' data-testid='navbar-signup-link' onClick={closeMenu}>
                Signup
              </NavBtnLink>
            </NavBtn>
          </NavListItem>
        </>
      )}
    </NavMenu>
  </Nav>
)

Navbar.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
}

Navbar.defaultProps = {
  // ! TODO: CHANGE BACK AFTER IMPLEMENTING AUTHENTICATION
  isAdmin: true,
}

export default Navbar
