import styled from 'styled-components'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'

export const Container = styled.div`
  padding: 2% 3%;
  margin: 0;
  justify-content: center;
  align-items: center;
`

export const ResponsiveContent = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    flex-direction: row;
    width: 100%;
  }
`

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`

export const SectionTitle = styled.h2`
  font-size: 3rem;
  text-transform: uppercase;
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    font-size: 2rem;
  }
`

export const Title = styled.h2`
  margin-top: 4%;
  margin-bottom: 6%;
  font-size: 1.5em;
  color: black;
  background-color: white;
`

export const CenteredContent = styled.div`
  text-align: center;
`

export const Emphasis = styled.strong`
  color: #11bb11;
  font-weight: 700;
`
