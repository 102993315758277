import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { createComment as CreateComment } from '../../../graphql/mutations'
import alertsReducer, { initialState } from '../../../reducers/alerts.reducer'
import { Container, Column, Row } from '../../shared/layout'
import { Label, Button } from '../../shared/interactive'
import { SET_COMMENT_ALERTS } from '../../../util/actionTypes'
import { COMMENT } from '../../../util/constants'

const initialFormState = {
  type: COMMENT,
  text: '',
}

function CommentAlert(props) {
  const { clientId } = props
  const [state, dispatch] = useReducer(alertsReducer, initialState)

  async function createAlert(values) {
    const { type, text } = values
    if (type === '' || text === '') return
    const alert = { type, clientId, text }
    const comments = [...state.comments, alert]
    dispatch({ type: SET_COMMENT_ALERTS, comments })
    try {
      const item = await API.graphql(graphqlOperation(CreateComment, { input: alert }))
      console.log('Alert created...', item)
    } catch (err) {
      console.log('ERROR creating alert...', err)
      const removed = state.alerts.filter(
        (item) =>
          !(
            item.type === alert.type &&
            item.clientId === alert.clientId &&
            item.text === alert.text
          )
      )
      dispatch({ type: SET_COMMENT_ALERTS, removed })
      alert('ERROR creating alert...', err)
    }
  }

  return (
    <Container>
      <Column>
        <Row>
          <Formik
            initialValues={initialFormState}
            validationSchema={Yup.object().shape({
              text: Yup.string()
                .min(10, 'Your comment must be a minimum of 10 characters')
                .max(250, 'Your comment is too long! Only 250 characters allowed.')
                .required('Please enter a comment for your alert'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              console.log('SUBMITTED: ', values)
              await createAlert(values).then(() => {
                setSubmitting(false)
                resetForm({ values: { ...initialFormState } })
              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              validateForm,
            }) => (
              <Form name='comment-alert' method='post' onSubmit={handleSubmit}>
                <Label htmlFor='text'>
                  Comment:
                  <Field
                    id='text'
                    name='text'
                    type='textarea'
                    value={values.text}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Label>
                {errors.comment && touched.comment && (
                  <ErrorMessage name='comment'>{(msg) => <p>{msg}</p>}</ErrorMessage>
                )}
                <Button type='submit' value='submit' size='large' onClick={() => validateForm()}>
                  {isSubmitting ? 'Sending...' : 'Send Alert'}
                </Button>
              </Form>
            )}
          </Formik>
        </Row>
      </Column>
    </Container>
  )
}

CommentAlert.propTypes = {
  clientId: PropTypes.string.isRequired,
}

export default CommentAlert
