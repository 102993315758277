import React, { useEffect, useState } from 'react'
// import axios from 'axios'
import { Column, Container, Row, Title } from '../../shared/layout'
import Table from '../../shared/Table'
import TableContent from '../../shared/TableContent'
import Lang from '../../../util/lang'

const Customers = () => {
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    // axios
    //   .get(process.env.REACT_APP_CUSTOMERS_URL, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   })
    //   .then((response) => {
    //     setState({ customers: response.body.customers })
    //   })
    setCustomers([
      {
        id: 1,
        created: '04/23/2020',
        name: 'Platinum',
        product: 'SMS_ALERTS',
        velocity: 0.33,
        userId: 23,
      },
    ])
  })

  return (
    <Container>
      <Row>
        <Title>Customers</Title>
      </Row>
      <Column>
        <Table headers={Lang.admin.customerFields} content={<TableContent items={customers} />} />
      </Column>
    </Container>
  )
}

export default Customers
