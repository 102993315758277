import {
  SET_TRADE_ALERTS,
  SET_COMMENT_ALERTS,
  // SET_TRADE_INPUT,
  // SET_COMMENT_INPUT,
  // CLEAR_TRADE_INPUT,
  // CLEAR_COMMENT_INPUT,
  ADD_TRADE_ALERT,
  ADD_COMMENT_ALERT,
} from '../util/actionTypes'

export const initialState = {
  trades: [],
  comments: [],
}

function alertsReducer(state, action) {
  switch (action.type) {
    case SET_TRADE_ALERTS:
      return {
        ...state,
        trades: action.trades,
      }
    case SET_COMMENT_ALERTS:
      return {
        ...state,
        comments: action.comments,
      }
    case ADD_TRADE_ALERT:
      return {
        ...state,
        trades: [...state.trades, action.trade],
      }
    case ADD_COMMENT_ALERT:
      return {
        ...state,
        comments: [...state.comments, action.comment],
      }
    // case SET_TRADE_INPUT:
    //   return {
    //     ...state,
    //     tradeForm: {
    //       ...state.tradeForm,
    //       [action.name]: action.value,
    //     },
    //   }
    // case SET_COMMENT_INPUT:
    //   return {
    //     ...state,
    //     commentForm: {
    //       ...state.commentForm,
    //       [action.name]: action.value,
    //     },
    //   }
    // case CLEAR_TRADE_INPUT:
    //   return {
    //     ...state,
    //     tradeForm: {
    //       ...initialState.tradeForm,
    //     },
    //   }
    // case CLEAR_COMMENT_INPUT:
    //   return {
    //     ...state,
    //     commentForm: {
    //       ...initialState.commentForm,
    //     },
    //   }

    default:
      return state
  }
}

export default alertsReducer
