/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:da7de232-456a-46b8-9b43-2e786fe8bf0b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FBxMSa57W",
    "aws_user_pools_web_client_id": "4hit6if52q3vq12827qercd2ve",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://fd2pvfrhpjg3pjuqthin7lv4u4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-iufnkgx4trbqdgvxc5uvndw2gu",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://49zvvqh7r2.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
