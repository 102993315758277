// import React from 'react'
// import PropTypes from 'prop-types'
import {
  // useLocation,
  // matchRoutes,
  Link,
} from 'react-router-dom'
// import AppRoutes from '../../routes/AppRoutes'
import styled, { css } from 'styled-components'
import { PHONE_UPPER_BOUND, TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'

export const Button = styled.button`
  display: inline-block;
  border-radius: 15px;
  padding: 1.5rem 2.5rem;
  width: 13rem;
  cursor: pointer;
  font-size: ${({ text }) => `${text}rem` || '2rem'};
  color: ${({ color }) => color || '#fff'};
  background: #11bb11;
  transition: all 0.2s ease-in-out;
  border: 2px solid #fff;
  &:hover,
  &:focus {
    color: #404040;
    background: #78ef19;
    /* transform: scale(1.1); */
    border-color: green;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.25;
      cursor: not-allowed;
    `}

  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    padding: 1rem 2rem;
    width: 11rem;
  }
  @media only screen and (max-width: ${PHONE_UPPER_BOUND}) {
    width: 9rem;
    padding: 0.5rem 0;
  }
`

export const Form = styled.form`
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 4% 6%;
  text-align: center;
  color: black;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.5);
`

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3% 0;
  margin-bottom: 4%;
  font-size: 0.8rem;
  color: #333;
`

export const Input = styled.input`
  display: flex;
  width: 100%;
  height: 2.5rem;
  border: 1px solid #333;
  background: #fff;
  color: #000;
  padding: 2%;
  font-size: 1.3rem;
  margin-bottom: 4%;
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 100%;
    font-size: 1.8rem;
  }
`

// export const TextInput = ({ disabled, id, label, placeholder, required }) => (
//   <Container>
//     {label && (
//       <Label htmlFor={id} required={required}>
//         {label}
//       </Label>
//     )}
//     <Input
//       aria-label={label}
//       aria-required={required}
//       disabled={disabled}
//       id={id}
//       placeholder={placeholder}
//       type='text'
//     />
//   </Container>
// )

// TextInput.propTypes = {
//   disabled: PropTypes.bool,
//   id: PropTypes.string.isRequired,
//   label: PropTypes.string,
//   placeholder: PropTypes.string,
//   required: PropTypes.bool,
// }

// TextInput.defaultProps = {
//   disabled: false,
//   label: '',
//   placeholder: '',
//   required: false,
// }

export const CheckBox = styled.input`
  display: flex;
  align-self: flex-start;
`

export const LinkedCard = styled(Link)`
  width: 20%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 5%;
  margin: 3%;
  text-decoration: none;
  color: #000;
  border: 1px solid #e4e4e4;
  transition: all 160ms ease-in-out;
  h2,
  svg {
    transition: all 160ms ease-in-out;
  }
  &:active,
  &:hover {
    box-shadow: 0 2px 6px 0 rgb(32 30 37 / 20%);
    transform: translateY(-3px);
  }
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    padding: 2%;
    margin: 4%;
    width: 100%;
  }
`

// export function NavLink({ to, exact, className, activeClassName, inactiveClassName }) {
//   const location = useLocation()
//   // const resolvedLocation = useResolvedLocation(to)
//   const routeMatches = matchRoutes(AppRoutes, location)

//   let isActive
//   if (exact) {
//     // isActive = location.pathname === resolvedLocation.pathname
//     isActive = location.pathname === to
//   } else {
//     isActive = routeMatches.some(
//       (match) =>
//         // return match.pathname === resolvedLocation.pathname
//         match.pathname === to
//     )
//   }

//   const allClassNames = className + (isActive ? ` ${activeClassName}` : ` ${inactiveClassName}`)

//   return <Link className={allClassNames} to={to} />
// }

// NavLink.propTypes = {
//   to: PropTypes.string.isRequired,
//   exact: PropTypes.bool,
//   className: PropTypes.string,
//   activeClassName: PropTypes.string,
//   inactiveClassName: PropTypes.string,
// }

// NavLink.defaultProps = {
//   exact: false,
//   className: '',
//   activeClassName: '',
//   inactiveClassName: '',
// }
