import React from 'react'
import styled from 'styled-components'
import ScrollableAnchor from 'react-scrollable-anchor'
import Reveal from 'react-reveal/Reveal'
import { MdSms } from 'react-icons/md'
import { Container, Column, SectionTitle, Emphasis } from '../shared/layout'
import TestimonialsCarousel from './TestimonialsCarousel'

const TestimonialsTitle = styled(SectionTitle)`
  text-transform: uppercase;
  text-align: center;
  font-size: 3rem;
`

const TestimonialContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 2%;
`

const Testimonials = () => (
  <section data-testid='testimonials-section'>
    <ScrollableAnchor id='testimonials'>
      <Container data-testid='testimonials-container'>
        <Column>
          <Reveal effect='slideInRight'>
            <TestimonialsTitle data-testid='testimonials-title'>
              What our <Emphasis>subscribers</Emphasis> say <MdSms style={{ color: '#11bb11' }} />
            </TestimonialsTitle>
          </Reveal>
          <TestimonialContent data-testid='testimonials-content'>
            <TestimonialsCarousel data-testid='testimonials-carousel' />
          </TestimonialContent>
        </Column>
      </Container>
    </ScrollableAnchor>
  </section>
)

export default Testimonials
