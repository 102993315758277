import React from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { useRoutes } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import Header from './components/Header/Header'
import AppRoutes from './routes/AppRoutes'
import Footer from './components/Footer/Footer'

const GlobalStyles = createGlobalStyle`
   body {
     margin: 0;
     padding: 0;
     background: #fff;
     font-family: Open-Sans, Helvetica, Sans-Serif;
   }
 `

const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-flow: column nowrap;
  overflow-x: hidden;
  overflow-y: auto;
`

const MainContent = styled.main`
  flex: 1;
`

const App = () => {
  const element = useRoutes(AppRoutes)

  return (
    <>
      <GlobalStyles />
      <AppContainer data-testid='app-container'>
        <Header data-testid='app-header' isLoggedIn={false} />
        <MainContent>{element}</MainContent>
        <Footer data-testid='app-footer' isLoggedIn={false} />
      </AppContainer>
    </>
  )
}

export default withAuthenticator(App, { includeGreetings: true })
