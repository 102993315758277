import React from 'react'
import Hero from '../components/Hero/Hero'
import Countdown from '../components/Countdown/Countdown'
import WhyChooseUs from '../components/WhyChooseUs/WhyChooseUs'
import RecentWinners from '../components/RecentWinners/RecentWinners'
import HowItWorks from '../components/HowItWorks/HowItWorks'
import Sectors from '../components/Sectors/Sectors'
import PennyStocks from '../components/PennyStocks/PennyStocks'
import Testimonials from '../components/Testimonials/Testimonials'
import CallToSubscribe from '../components/Subscribe/CallToSubscribe'

const Home = () => (
  <div>
    <Hero />
    <Testimonials />
    <WhyChooseUs />
    <Countdown />
    <RecentWinners />
    <HowItWorks />
    <Sectors />
    <PennyStocks />
    <CallToSubscribe />
  </div>
)

export default Home
