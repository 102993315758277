import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import { FaBell, FaBullhorn, FaUserFriends } from 'react-icons/fa'
import { RiHandCoinFill } from 'react-icons/ri'
import { Container, Column, Row, Title } from '../components/shared/layout'
import {
  Nav,
  NavListItem,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from '../components/Header/NavbarElements'
import { TABLET_PORTRAIT_UPPER_BOUND, TABLET_LANDSCAPE_UPPER_BOUND } from '../util/constants'

const AdminContainer = styled(Container)`
  margin-bottom: 8%;
`

const HeaderRow = styled(Row)`
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #11ff11;
  color: #fff;
  font-size: 2.5rem;
`

const TitleLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  padding: 0.5% 2%;
  color: #f82;
  transition: all 0.2s ease-in-out;
  /* h2 {
    color: #fff;
    background-color: transparent;
  } */
  &:hover {
    transform: translateY(-3px);
    shadow h2 {
      text-shadow: 1px 2px 6px 4px rgb(32 30 37 / 20%);
      color: #f82;
    }
  }
`

const StyledNav = styled(Nav)`
  background-color: transparent;
  ${NavLink} {
    color: #fff;
    background-color: transparent;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    &:hover {
      transform: scale(1.05);
      color: #f82;
    }
    &.active {
      border-bottom: 2px solid #fff;
    }
  }
  ${NavBtnLink} {
    background: #fff;
    color: #f82;
    font-size: 2rem;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: #f82;
      color: #fff;
    }
    @media only screen and (max-width: ${TABLET_LANDSCAPE_UPPER_BOUND}) {
      font-size: 1.1.rem;
    }
    @media only scren and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
      font-size: 0.9rem;
    }
  }
`

const iconStyles = { width: '1rem', height: '1rem' }

const Admin = () => (
  <section>
    <AdminContainer data-cy='admin-container'>
      <Column>
        <header>
          <HeaderRow>
            <TitleLink to=''>
              <Title>Admin</Title>
            </TitleLink>
            <StyledNav>
              <Bars />
              <NavMenu>
                <NavListItem>
                  <NavLink to='alerts' end>
                    <FaBell style={iconStyles} />
                    Alerts
                  </NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink to='products' end>
                    <RiHandCoinFill style={iconStyles} />
                    Products
                  </NavLink>
                </NavListItem>
                <NavListItem>
                  <NavLink to='customers' end>
                    <FaUserFriends style={iconStyles} />
                    Customers
                  </NavLink>
                </NavListItem>
                <NavListItem>
                  <NavBtn>
                    <NavBtnLink to='alerts/send-alert'>
                      <FaBullhorn
                        style={{
                          width: '2rem',
                          height: '2rem',
                          marginRight: '0.5rem',
                          transform: 'scaleX(-1)',
                        }}
                      />
                      Send Alert
                    </NavBtnLink>
                  </NavBtn>
                </NavListItem>
              </NavMenu>
            </StyledNav>
          </HeaderRow>
        </header>
        <Row>
          <Outlet />
        </Row>
      </Column>
    </AdminContainer>
  </section>
)

export default Admin
