import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PHONE_UPPER_BOUND, TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'

const SectorBoxContainer = styled.li`
  list-style: none;
  width: calc(33% - 8.2rem);
  float: left;
  text-align: center;
  margin: 1rem;
  padding: 6% 4%;
  background: #000;
  color: #fff;
  border: 1px solid #11bb11;
  > div {
    display: flex;
    justify-content: center;
  }
  &.hover {
    box-shadow: 1px 1px 2px 2px #fff;
  }
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: calc(50% - 5.8rem);
  }
  @media only screen and (max-width: ${PHONE_UPPER_BOUND}) {
    width: 82%;
  }
`

const SectorIcon = styled.div`
  font-size: xx-large;
  color: #11bb11;
`

const SectorName = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
`

const SectorBox = (props) => {
  const { icon, name } = props
  return (
    <SectorBoxContainer data-testid='sector-box'>
      <SectorIcon data-testid='sector-icon'>{icon}</SectorIcon>
      <SectorName data-testid='sector-name'>{name}</SectorName>
    </SectorBoxContainer>
  )
}

SectorBox.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
}

export default SectorBox
