import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal'
import { Column } from '../shared/layout'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'

const CounterContainer = styled.div`
  font-size: xx-large;
  color: #000;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 50%;
  }
`
const CountDays = styled.div`
  font-size: 18rem;
  letter-spacing: 0.8rem;
  text-indent: 15%;
`
const DayDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  text-indent: 15%;
`

const DaySpan = styled.span`
  padding-top: 4rem;
`

const HMSCounter = styled.div`
  /* padding: 1rem 2rem; */
  padding: 0 20%;
`

const Counter = ({ startHour, startMinute, startSecond }) => {
  const [hours, setHours] = useState(startHour)
  const [minutes, setMinutes] = useState(startMinute)
  const [seconds, setSeconds] = useState(startSecond)

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(myInterval)
          } else {
            setHours(hours - 1)
            setMinutes(minutes - 1)
            setSeconds(seconds - 1)
          }
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <CounterContainer data-testid='countdown-counter'>
      <Reveal effect='slideInRight'>
        <DayDiv data-testid='countdown-days'>
          <CountDays>01</CountDays>
          <DaySpan>DAYS</DaySpan>
        </DayDiv>
      </Reveal>
      <Column>
        <Reveal effect='slideInRight' delay={200}>
          <HMSCounter data-testid='countdown-hms-counter'>
            {hours}H {minutes}M {seconds}S
          </HMSCounter>
        </Reveal>
      </Column>
    </CounterContainer>
  )
}

Counter.propTypes = {
  startHour: PropTypes.number,
  startMinute: PropTypes.number,
  startSecond: PropTypes.number,
}

Counter.defaultProps = {
  startHour: 19,
  startMinute: 26,
  startSecond: 59,
}

export default Counter
