import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
// import { API, graphqlOperation } from 'aws-amplify'
// creates new unique uuid
// import { v4 as uuid } from 'uuid'
// import mutation and query
// import { listAlerts as ListAlerts } from '../../../graphql/queries'
// import { createAlert as CreateAlert } from '../../../graphql/mutations'
// import { onCreateAlert as OnCreateAlert } from '../../../graphql/subscriptions'
import { Container, Row, Column, Title } from '../../shared/layout'
import { Nav, NavListItem, NavLink, NavMenu } from '../../Header/NavbarElements'

const TitleRow = styled(Row)`
  width: 100%;
`

const AlertLink = styled(NavLink)``

// const CLIENT_ID = uuid()

function Alerts() {
  // const [state, dispatch] = useReducer(alertsReducer, initialState)

  // async function getAlerts() {
  //   try {
  //     const apiData = await API.graphql(graphqlOperation(ListAlerts))
  //     console.log(apiData)
  //     dispatch({ type: SET_ALERTS, alerts: apiData.data.listAlerts.items })
  //   } catch (err) {
  //     console.log('ERROR fetching alerts...', err)
  //   }
  // }

  // async function createAlert() {
  //   const { created, type } = state
  //   if (created === '' || type === '') return
  //   const alert = { created, type, clientId: CLIENT_ID }
  //   const alerts = [...state.alerts, alert]
  //   dispatch({ type: SET_ALERTS, alerts })
  //   dispatch({ type: CLEAR_INPUT })
  //   try {
  //     await API.graphql(graphqlOperation(CreateAlert, { input: alert }))
  //     console.log('Alert created...')
  //   } catch (err) {
  //     // TODO: alert user that submit didn't work
  //     // TODO: remove alert from alerts array
  //     console.log('ERROR creating alert...', err)
  //   }
  // }

  // function onChange(e) {
  //   dispatch({ type: SET_INPUT, key: e.target.name, value: e.target.value })
  // }

  // useEffect(() => {
  //   getAlerts()
  //   const subscription = API.graphql(graphqlOperation(OnCreateAlert())).subscribe({
  //     next: (eventData) => {
  //       const alert = eventData.value.data.onCreateAlert
  //       if (alert.clientId === CLIENT_ID) return
  //       dispatch({ type: ADD_ALERT, alert })
  //     },
  //   })
  //   return () => subscription.unsubscribe()
  // }, [])

  return (
    <Container>
      <Column>
        <TitleRow>
          <Title>Alerts</Title>
          <Nav>
            <NavMenu>
              <NavListItem>
                <AlertLink to='' end>
                  Overview
                </AlertLink>
              </NavListItem>
              <NavListItem>
                <AlertLink to='send' end>
                  Send
                </AlertLink>
              </NavListItem>
            </NavMenu>
          </Nav>
        </TitleRow>
        <Row>
          <Outlet />
        </Row>
      </Column>
    </Container>
  )
}
export default Alerts
