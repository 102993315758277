import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { API, graphqlOperation } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
// import styled from 'styled-components'
import { createTrade as CreateTrade } from '../../../graphql/mutations'
import { Container, Column, Row } from '../../shared/layout'
import { Label, Button } from '../../shared/interactive'
import alertsReducer, { initialState } from '../../../reducers/alerts.reducer'
import { SET_TRADE_ALERTS } from '../../../util/actionTypes'
import { LONG, OPEN, TRADE } from '../../../util/constants'

const initialFormState = {
  type: TRADE,
  ticker: '',
  direction: LONG,
  quantity: '',
  price: '',
  leg: OPEN,
}

const TradeAlert = (props) => {
  const { clientId } = props
  const [state, dispatch] = useReducer(alertsReducer, initialState)

  async function createAlert(values) {
    const { type, ticker, direction, quantity, price, leg } = values
    if (
      type === '' ||
      ticker === '' ||
      direction === '' ||
      quantity === '' ||
      price === '' ||
      leg === ''
    )
      return

    const alert = {
      type,
      clientId,
      ticker: ticker.toUpperCase(),
      direction,
      quantity,
      price,
      leg,
    }
    const trades = [...state.trades, alert]
    dispatch({ type: SET_TRADE_ALERTS, trades })
    try {
      const item = await API.graphql(graphqlOperation(CreateTrade, { input: alert }))
      console.log('Alert created...', item)
    } catch (err) {
      console.log('ERROR creating alert...', err)
      const removed = state.alerts.filter(
        (item) =>
          !(
            item.type === alert.type &&
            item.clientId === alert.clientId &&
            item.ticker === alert.ticker &&
            item.direction === alert.direction &&
            item.quantity === alert.quantity &&
            item.price === alert.price &&
            item.leg === alert.leg
          )
      )
      dispatch({ type: SET_TRADE_ALERTS, removed })
      alert('ERROR creating alert...', err)
    }
  }

  return (
    <Container>
      <Column>
        <Row>
          <Formik
            initialValues={initialFormState}
            validationSchema={Yup.object().shape({
              ticker: Yup.string()
                .min(3, 'Your ticker is too short')
                .max(5, 'Your ticker is too long')
                .required('Please enter a valid stock ticker'),
              direction: Yup.string().required('Please enter a direction for your trade'),
              quantity: Yup.number()
                .min(0, 'You must select a quantity GREATER than 0')
                .positive('Quantity must be a POSITIVE number')
                .integer('Quantity must be a whole number of shares')
                .required('Please enter a quantity for your trade'),
              price: Yup.number()
                .positive('You must enter a POSITIVE price')
                .min(0.0, 'You must enter a price greater than $0.0001')
                .required('Price is a required field'),
              leg: Yup.string().required('Please enter a leg for your trade'),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              console.log('SUBMIT VALUES: ', values)
              await createAlert(values).then(() => {
                setSubmitting(false)
                resetForm({ values: { ...initialFormState } })
              })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              validateForm,
            }) => (
              <Form name='trade-alert' method='post' onSubmit={handleSubmit}>
                <Label htmlFor='ticker'>
                  Ticker:
                  <Field
                    id='ticker'
                    name='ticker'
                    type='text'
                    value={values.ticker.toUpperCase()}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Label>
                <ErrorMessage name='ticker'>{(msg) => <p>{msg}</p>}</ErrorMessage>
                <Label htmlFor='direction'>
                  Direction:
                  <Field
                    as='select'
                    id='direction'
                    name='direction'
                    type='select'
                    value={values.direction}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option>Long</option>
                    <option>Short</option>
                  </Field>
                </Label>
                {errors.direction && touched.direction && <p>{errors.direction}</p>}
                <Label htmlFor='quantity'>
                  Quantity
                  <Field
                    id='quantity'
                    name='quantity'
                    type='number'
                    min='1'
                    max='100000'
                    step='1'
                    value={values.quantity}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Label>
                <ErrorMessage name='quantity'>{(msg) => <p>{msg}</p>}</ErrorMessage>
                <Label htmlFor='price'>
                  Price: ($)
                  <Field
                    id='price'
                    name='price'
                    type='number'
                    min='0.00'
                    max='999.99'
                    step='0.0001'
                    value={values.price}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Label>
                <ErrorMessage name='price'>{(msg) => <p>{msg}</p>}</ErrorMessage>
                <div id='leg-group'>Side:</div>
                <fieldset role='group' aria-labelledby='leg-group'>
                  <Label htmlFor='leg-open'>
                    Open
                    <Field type='radio' name='leg' value='Open' />
                  </Label>
                  <Label htmlFor='leg-close'>
                    Close
                    <Field type='radio' name='leg' value='Close' />
                  </Label>
                </fieldset>
                <Button
                  type='submit'
                  value='submit'
                  size='large'
                  // disabled={!valid || isSubmitting}
                  onClick={() => validateForm()}
                >
                  {isSubmitting ? `Sending...` : `Send Alert`}
                </Button>
              </Form>
            )}
          </Formik>
        </Row>
      </Column>
    </Container>
  )
}

TradeAlert.propTypes = {
  clientId: PropTypes.string.isRequired,
}

export default TradeAlert
