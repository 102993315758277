import React from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal'
import Slide from 'react-reveal/Slide'
import { GiTakeMyMoney } from 'react-icons/gi'
import { Container, ResponsiveContent, SectionTitle, Emphasis } from '../shared/layout'
import WinnerBox from './WinnerBox'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'
import winnersListData from './winnersListData'
import Lang from '../../util/lang'

const WinnersContainer = styled(Container)`
  background-color: black;
  color: #fff;
  height: 60%;
`

const WinnersContent = styled(ResponsiveContent)`
  justify-content: center;
`

const WinnersTitle = styled(SectionTitle)`
  font-size: 3rem;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 2px 3px 2px #333;
`

const SubTitle = styled(Emphasis)`
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
`

const WinnersText = styled.div`
  text-align: left;
  font-size: 1.2rem;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 50%;
  }
`

const WinnersList = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 50%;
  }
`

const WinnerTextBlock = styled.p``

const RecentWinners = () => (
  <section data-testid='recent-winners-section'>
    <WinnersContainer data-testid='Winners-container'>
      <WinnersContent data-testid='recent-winners-content'>
        <WinnersText data-testid='recent-winners-text'>
          <Reveal effect='slideInLeft'>
            <WinnersTitle data-testid='recent-winners-title'>
              <Emphasis>
                <GiTakeMyMoney />
              </Emphasis>{' '}
              Recent <Emphasis>Winners</Emphasis>
            </WinnersTitle>
          </Reveal>

          <Slide left delay={200}>
            <SubTitle data-testid='recent-winners-subtitle'>{Lang.winners.subtitle}</SubTitle>
          </Slide>

          {Lang.winners.content.map((textBlock, i) => (
            <Slide left delay={(i + 1) * 200} key={textBlock.substr(0, 7)}>
              <WinnerTextBlock key={textBlock.substr(0, 7)} data-testid='recent-winners-text-block'>
                {textBlock}
              </WinnerTextBlock>
            </Slide>
          ))}
        </WinnersText>
        <WinnersList data-testid='recent-winners-list'>
          {winnersListData.map((winner) => (
            <WinnerBox
              key={winner.ticker}
              ticker={winner.ticker}
              gain={winner.gain}
              range={winner.range}
            />
          ))}
        </WinnersList>
      </WinnersContent>
    </WinnersContainer>
  </section>
)

export default RecentWinners
