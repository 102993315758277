import React from 'react'
import styled from 'styled-components'
import Slide from 'react-reveal/Slide'
import LightSpeed from 'react-reveal/LightSpeed'
import { HiLightBulb } from 'react-icons/hi'
import { BsLightningFill } from 'react-icons/bs'
import { Container, Column, ResponsiveContent, SectionTitle, Emphasis } from '../shared/layout'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'
import benefitsListData from './benefitsListData'

const WhyChooseContainer = styled(Container)`
  background-color: #ddd;
`

const WhyChooseTitle = styled(SectionTitle)`
  text-transform: uppercase;
  font-size: 3rem;
`

const BenefitsText = styled.div`
  font-weight: 500;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 50%;
    padding-right: 2%;
  }
`

const BenefitsList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-inline-start: 20px;
  margin-top: 0;
  font-size: 1.3rem;
  list-style: none;
`

const iconStyles = {
  marginLeft: '-1.9em',
  marginTop: '0.3em',
  marginRight: '0.6em',
  width: '1em',
  border: '3px solid #11bb11',
  borderRadius: '50px',
  color: '#fff',
  backgroundColor: '#11bb11',
}

const BenefitsListItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 2%;
  padding-left: 5%;
`

const BenefitsVideo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  @media only screen and (max-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 100%;
  }
`

const WhyChooseUs = () => (
  <section data-testid='why-choose-us-section'>
    <WhyChooseContainer data-testid='why-choose-us-container'>
      <ResponsiveContent data-testid='why-choose-us-content'>
        <BenefitsText data-testid='benefits-text'>
          <Column>
            <Slide left>
              <WhyChooseTitle data-testid='why-choose-us-title'>
                <Emphasis>
                  <HiLightBulb />
                </Emphasis>{' '}
                Why <Emphasis>Choose Us</Emphasis>
              </WhyChooseTitle>
            </Slide>
            <BenefitsList data-testid='benefits-list'>
              {benefitsListData.map((benefit, i) => (
                <LightSpeed left delay={(i + 1) * 200} key={`${benefit.name}`}>
                  <BenefitsListItem key={`${benefit.name}`} data-testid='benefits-list-item'>
                    <BsLightningFill style={iconStyles} />
                    {benefit.text}
                  </BenefitsListItem>
                </LightSpeed>
              ))}
            </BenefitsList>
          </Column>
        </BenefitsText>

        <BenefitsVideo data-testid='benefits-video'>
          <img src='../assets/wall-street-panorama.jpg' alt='benefits-video' />
        </BenefitsVideo>
      </ResponsiveContent>
    </WhyChooseContainer>
  </section>
)

export default WhyChooseUs
