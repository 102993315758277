import React from 'react'
import styled from 'styled-components'
import Reveal from 'react-reveal/Reveal'
import Jump from 'react-reveal/Jump'
import { BiChevronDown } from 'react-icons/bi'
import { Column, Row } from '../shared/layout'
import { Button } from '../shared/interactive'
import { TABLET_PORTRAIT_UPPER_BOUND } from '../../util/constants'
import '../../stylesheets/Hero.css'
import Lang from '../../util/lang'

const Background = styled.div`
  /* background-color: #282c34; */
  min-height: 625px;
  position: relative;
  background-image: url('../assets/green_stocks_money.jpg');
  background-position: 100% 75%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  color: white;
  align-items: center;
  justify-content: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const HeroText = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  width: 90%;
  height: 80%;
  align-items: flex-start;
  line-height: 2.9rem;
  letter-spacing: 0.1rem;
  font-size: 3rem;
  padding: 5%;
  overflow: hidden;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 70%;
  }
`

const Strong = styled.strong`
  text-transform: uppercase;
  color: #ff2233;
  font-size: 5rem;
`

const HeroTitle = styled.h1`
  font-size: 4rem;
`

const Subtitle = styled.p`
  width: 100%;
  font-size: 1.2rem;
  color: #ccc;
  line-height: 1.7rem;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    width: 60%;
  }
`

const SubscribeButton = styled(Button)`
  text-transform: uppercase;
  padding: 3% 6%;
  border-radius: 20px;
  margin-top: 3%;
  font-size: 0.9rem;
  @media only screen and (min-width: ${TABLET_PORTRAIT_UPPER_BOUND}) {
    padding: 4% 7%;
    font-size: 1.3rem;
  }
`

const SubscribeLink = styled.a`
  text-decoration: none;
  color: #fff;
`

const ScrollDownLink = styled.a`
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 50px;
  height: 10%;
  margin-bottom: 1%;
  font-size: 2rem;
  background: transparent;
  border: 3px solid #11bb11;
  color: #11bb11;
`

const Centered = styled(Row)`
  position: absolute;
  bottom: 1%;
  justify-content: center;
  align-items: center;
  clear: both;
`

const Hero = () => (
  <section data-testid='hero-section'>
    <Background data-testid='hero-bg'>
      <Overlay>
        <Wrapper>
          <Column>
            <HeroText>
              <Reveal effect='zoomInDown'>
                <HeroTitle data-testid='hero-title'>
                  Welcome to <Strong>Bid Bull</Strong>
                </HeroTitle>
              </Reveal>
              <Reveal effect='slideInLeft'>
                <Subtitle data-testid='hero-subtitle'>{Lang.hero.subtitle}</Subtitle>
              </Reveal>
              <Reveal effect='bounceInLeft'>
                <SubscribeButton data-testid='hero-subscribe-btn'>
                  <SubscribeLink href='/signup' data-testid='hero-subscribe-link'>
                    {Lang.hero.callToAction}
                  </SubscribeLink>
                </SubscribeButton>
              </Reveal>
            </HeroText>
          </Column>
          <Centered>
            <Jump big forever duration={2500}>
              <ScrollDownLink href='#testimonials' data-testid='hero-scroll-btn'>
                <BiChevronDown style={{ color: '#11bb11' }} />
              </ScrollDownLink>
            </Jump>
          </Centered>
        </Wrapper>
      </Overlay>
    </Background>
  </section>
)

export default Hero
