import React from 'react'
import { IoLayersOutline } from 'react-icons/io5'
import { GiEnergise, GiChestnutLeaf, GiMining } from 'react-icons/gi'
import { GoBeaker } from 'react-icons/go'
import { GrBitcoin } from 'react-icons/gr'

const sectorListData = [
  {
    name: 'Technology',
    icon: <IoLayersOutline />,
  },
  {
    name: 'Energy',
    icon: <GiEnergise />,
  },
  {
    name: 'BioTech',
    icon: <GoBeaker />,
  },
  {
    name: 'Medical Marijuana',
    icon: <GiChestnutLeaf />,
  },
  {
    name: 'Blockchain',
    icon: <GrBitcoin />,
  },
  {
    name: 'Metals & Mining',
    icon: <GiMining />,
  },
]

export default sectorListData
